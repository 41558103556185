import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { fetchSettings } from '../../store/settings';
import { logOut } from '../../store/context';
import PageBanner from '../PageBanner';
// import Footer from '../Footer';
import classNames from 'classnames';
import LogoSrc from '../images/logo/research2u_logo.png';
import iECLogoSrc from '../images/logo/iEC professional logo.png';
import { Helmet } from 'react-helmet';

const LoginLayout = props => {
	React.useEffect(() => {
		// Logout user if timed out
		if (props.timeout) props.logout();

		if (!props.isLoggedIn || props.timeout) {
			props.fetchSettings([
				"loginAcceptTermsContent",
				"termsDialogContent",
				"siteLinks",
				"copyrightContent",
				"disclaimerContent",
				"showPageBanner",
				"pageBannerContent",
				"loginBackground",
				"registrationInitialText"	
			]);
		}
	}, []);

	let backgroundImage = null;
	if (!props.loading) {
		if (props.centerContent) {
			backgroundImage = props.backgroundImage || props.loginBackground || "/images/aspire-mountain.png";
		} else {
			backgroundImage = props.backgroundImage || props.loginBackground || "/images/login_background.jpg";
		}
	}

	return (
		<main role="main" className={classNames({ "show-banner": props.showBanner, "login-layout": true, "centered-layout": props.centerContent })}>
            <Helmet>
                <title>{props.tenantTitle || ''} - Login</title>
                {props.favicon && <link rel="shortcut icon" href={props.favicon} />}
            </Helmet>

			{props.showBanner && <PageBanner />}
			<div className="signin__main">
				<div className="signin__background" style={{ backgroundImage: `url(${backgroundImage})` }} />
				<div className="signin__content">
					<div>
						<h1 className="signin__logo logo-primary text-center">
							{!props.loading && <Link to="/"><img src={props.logo || LogoSrc} className="logo" alt="Research 2 U" style={{ maxHeight: '100px', maxWidth: '90%' }} /></Link>}
						</h1>
						{props.children}
					</div>
					{props.showPoweredBy &&
						<div style={{
							textAlign: 'center',
							marginTop: '40px'
						}}>
							<span>Powered by</span>
							<img src={LogoSrc} height="50" className="logo" alt="" style={{ padding: '10px' }} />
						</div>
					}

					<div style={{
						textAlign: 'center',
						width: '100%',
						marginTop: '20px',
					}}>
						<div>Research2U is an initiative of</div>
						<img src={iECLogoSrc} height="50" className="logo" alt="" style={{ padding: '10px' }} />
					</div>
				</div>
			</div>
			{/* <Footer /> */}
		</main>
	);
};

LoginLayout.props = {
	isLoggedIn: PropTypes.bool,
	loading: PropTypes.bool.isRequired,
	showBanner: PropTypes.bool.isRequired,
	logo: PropTypes.string,
	loginBackground: PropTypes.string,
	backgroundImage: PropTypes.string,
	timeout: PropTypes.bool.isRequired,
	centerContent: PropTypes.bool,
	tenantTitle: PropTypes.string,
	showPoweredBy: PropTypes.bool,
	favicon: PropTypes.string
};

LoginLayout.defaultProps = {
	isLoggedIn: false,
	logo: null,
	loginBackground: null,
	backgroundImage: null,
	favicon: null,
	centerContent: false,
	tenantTitle: '',
	showPoweredBy: false
};

const mapStateToProps = state => ({
	isLoggedIn: state.context.isLoggedIn,
	showBanner: state.settings.showPageBanner,
	logo: state.settings.logo,
	loading: state.bootstrap.loading,
	loginBackground: state.settings.loginBackground,
	backgroundImage: state.settings.backgroundImage,
	favicon: state.settings.favicon,
	tenantTitle: state.context.tenantTitle,
	showPoweredBy: state.settings.showPoweredBy
});

const mapDispatchToProps = dispatch => ({
	fetchSettings: names => dispatch(fetchSettings(names)),
	logout: () => dispatch(logOut(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginLayout));